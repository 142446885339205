<template>
  <div>
    <div class="login-bg h-100" style="margin-top:100px;">
      <div class="container h-100">
        <div class="row justify-content-center h-100">
          <div class="col-xl-6">
            <div class="form-input-content login-form">
              <div class="card">
                <div class="card-body">
                  <div class="logo text-center">
                    <a href="/signin">
                      <img src="../../assets/logo_red.png" height="59" width="225" />
                    </a>
                  </div>
                  <h4 class="text-center mt-4">Enter your email</h4>
                  <form class="mt-5 mb-5" @submit.prevent="onSubmit">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        id="email"
                        v-model="email"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <span v-if="emailError" class="invalid-field">{{
                          emailError
                        }}</span>
                      </div>
                      <div class="form-group col-md-6 text-right">
                        <router-link to="/signin">
                          Login to your account?
                        </router-link>
                      </div>
                    </div>
                    <div class="text-center mb-4 mt-4 recaptcha-container">
                      <g-recaptcha
                        :data-sitekey="siteKey" 
                        data-size="normal"
                        data-btn-class="recaptcha-button"
                        :data-validate="onValidate"
                        :data-callback="onCallback"
                      >
                        <button type="submit" class="btn btn-primary">
                          Reset Password
                        </button>
                      </g-recaptcha>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PASSWORD_RESET_REQUEST } from "../../store/modules/auth/actions";
import {
//   NOTIFICATION_REQUEST,
//   NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
//   NOTIFICATION_CLOSE
} from "../../store/modules/ui/actions";
import { Message } from "element-ui";
import gRecaptcha from '@finpo/vue2-recaptcha-invisible';

export default {
  components: {
    gRecaptcha,
  },
  data() {
    return {
      email: "",
      emailError: null,
      siteKey: process.env.VUE_APP_SITE_KEY,
    };
  },
  methods: {
    validateEmail() {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!this.email) {
        this.emailError = "Email is required.";
        return false;
      } else if (!re.test(this.email)) {
        this.emailError = "Please enter a valid email address.";
        return false;
      }
      
      this.emailError = null;
      return true;
    },
    onValidate() {
      return this.validateEmail();
    },
    onCallback(token) {
      if (token) {
        this.onSubmit();
      } else {
        this.$store.dispatch(NOTIFICATION_ERROR, {
          message: "Please complete the reCAPTCHA."
        });
      }
    },
    onSubmit() {
      // this.$store.dispatch(NOTIFICATION_REQUEST, {
      //   message: "Submitting..."
      // });

      this.$store
        .dispatch(PASSWORD_RESET_REQUEST, { username: this.email })
        .then(resp => {
          if (resp.resultCode === 0) {
            // this.$store.dispatch(NOTIFICATION_SUCCESS, {
            //   message: "Password reset email sent to your given email address."
            // });
            Message({
              message: "Password reset email sent to '" + this.email + "'.",
              type: "success",
              duration: 5 * 1000
            });
            this.email = "";
          } else {
            // this.$store.dispatch(NOTIFICATION_CLOSE, {
            //   message: resp.content.message
            // });
          }
        });
    }
  }
};
</script>

<style>
.recaptcha-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .recaptcha-button {
    border: 0 !important;
    margin-top: 1.5rem !important;
    max-width: 20rem;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
